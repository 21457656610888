<template>
    <div style="width: 100%; height: 100%">
        <!-- pdfjs中viewer.html嵌入iframe中 -->
        <iframe
            style="width: 100%; height: 100%"
            ref="pdfWater"
            :src="pdfSrc"
        ></iframe>
    </div>
</template>
<script>
import { preview } from '@/utils/download'
export default {
    props: {
        url: String,
    },
    data() {
        return {
            pdfSrc: './pdfjs/web/viewer.html?file=',
        }
    },
    created() {
        this.pdfSrc = `./pdfjs/web/viewer.html?file=${this.url}`
        return
        preview(this.url)
            .then(res => {
                // 拼接文件地址
                console.log(res)
                this.pdfSrc = `./pdfjs/web/viewer.html?file=${res}`
            })
            .catch(err => {})
    },
}
</script>