<template>
    <div>
        <Title>
            <div class="h2_inner">
                <div class="he_header">
                    <svg-icon icon-class="icon_theme" class="icon"></svg-icon>
                    <span>关联模型</span>
                </div>
                <div class="page" v-if="list.length > 0">
                    <a-pagination 
                        simple 
                        :default-current="1" 
                        :total="total" 
                        :pageSize="params.pageSize"
                        @change="pageChange"
                    />
                </div>
            </div>
        </Title>
        <div class="list">
            <a-list  :data-source="list" :loading="loading">
                <a-list-item slot="renderItem" slot-scope="item">
                    <Item :data="item"/> 
                </a-list-item>
            </a-list>
            <!-- <CubeItem v-for="item in list" :key="item.Id" :data="item"/> -->
        </div>
    </div>
</template>
<script>
import Title from '~/components/Learning/Info/Title.vue'
import Item from '~/components/Learning/List/CubeItem.vue'
import { GetListByIds } from '@/api/model'
export default {
    props:['modelIds'],
    components:{
        Title,
        Item
    },
    data(){
        return{
            params:{
                cardId:'',
                pageIndex:1,
                pageSize:3
            },
            total:0,
            loading:false,
            list:[],
        }
    },
    methods:{
        pageChange(page, pageSize){
            
            this.params.pageIndex = page
            this.getRealateList()
            // this.getData()
        },
        async getRealateList(){
            this.loading = true
            this.params.cardId = this.$route.params.id
            await GetListByIds({ids:this.modelIds}).then(res => {
                const { data , total} = res
                this.list = data || []
                this.total = total
            }).catch(e=>e)
            this.loading = false
        }
    },
    created(){
        this.getRealateList()
    }
}
</script>
<style lang="less" scoped>
/deep/.item{
    margin-right: 0 !important;
}
/deep/.ant-list-item{
    border: 0 !important;
}
</style>