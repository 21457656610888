<template>
    <div class="info">
        <a-button type="primary" class="goback" @click="$router.go(-1)">
            <a-icon type="left" />
            返回
        </a-button>
        <div class="auto-1440">
            <Breadcrumb :title="info.Title" type="grey" />
            <div class="section">
                <div class="left">
                    <!-- <div class="title-wrap">
                        <h1>{{ info.Title }}</h1>
                        <div class="infoitem">
                            <div class="label">标签：</div>
                            <div class="inner tag_box">
                                <Tag v-for="(item, index) in tags" :key="index" :name="item" />
                            </div>
                        </div>
                        <div class="infoitem">
                            <div class="label">简介：</div>
                            <div class="inner des">
                                {{ info.Info }}
                            </div>
                        </div>
                    </div> -->
                    <div class="pdf_wrap">
                        <div class="btn">
                            <!-- <div class="item">意见反馈</div> -->
                        </div>
                        <div class="iframe">
                            <waterPDF :url="info.FileUrl" v-if="info.FileUrl" />
                            <!-- <iframe :src="info.FileUrl"></iframe> -->
                        </div>
                    </div>
                </div>
                <div class="right">
                    <div class="title-wrap">
                        <h1>
                            <span class="dot2">{{ info.Title }}</span>
                            <a-tooltip placement="top" title="收藏">
                                <a-button shape="circle" icon="heart"  @click="ToggleCollectLibrary"/>
                            </a-tooltip>
                            &nbsp;
                            <a-tooltip placement="top" title="下载" v-if="info.FileUrl">
                                <a-button type="primary" shape="circle" icon="download" @click="getLink" />
                            </a-tooltip>
                            <!-- <a-button type="primary" v-if="info.FileUrl" @click="getLink">
                                <a-icon type="download" />收藏
                            </a-button>
                            <a-button type="primary" v-if="info.FileUrl" @click="getLink">
                                <a-icon type="download" />下载
                            </a-button> -->
                        </h1>
                        <div class="infoitem">
                            <div class="label">标签：</div>
                            <div class="inner tag_box">
                                <Tag v-for="(item, index) in tags" :key="index" :name="item" />
                            </div>
                        </div>
                        <div class="infoitem">
                            <div class="label">简介：</div>
                            <div class="inner des dot2">
                                {{ info.Info }}
                            </div>
                        </div>
                    </div>
                    <div class="relateModel">
                        <RelateModel :modelIds="info.ModelIds" v-if="info.ModelIds" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Breadcrumb from "~/components/common/Breadcrumb";
import Tag from "~/components/common/Tag";
import RelateModel from "~/components/Library/Info/RelateModel";
import waterPDF from "@/components/common/waterPDF";
import { ToggleCollectLibrary} from '@/api/collection'
import { GetDataById } from "@/api/libarary";
export default {
    metaInfo() {
        return {
            title: this.info.SEOTitle || this.info.Title || "文物出版社",
            meta: [
                {
                    name: "keyWords",
                    content: this.info.SEOKeyword,
                },
                {
                    name: "description",
                    content: this.info.SEODescription,
                },
            ],
        };
    },
    components: {
        Breadcrumb,
        Tag,
        RelateModel,
        waterPDF,
    },
    data() {
        return {
            info: {},
            link: "",
        };
    },
    computed: {
        tags() {
            this.info.Tags = this.info.Tags || "";
            return this.info.Tags.split(",");
        },
    },
    methods: {
        async getData() {
            const { data } = await GetDataById({ id: this.$route.params.id });
            this.info = data;
            // this.getLink();
        },
        getLink() {
            let that = this;
            const xhr = new XMLHttpRequest();
            xhr.open("get", this.info.FileUrl);
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (this.status === 200) {
                    const blob = this.response;
                    const url = URL.createObjectURL(blob);
                    // 将转化后 url 赋值给 vue-pdf 插件
                    // that.link = url;
                    const a = document.createElement("a");
                    a.href = url;
                    a.target = "_blank";
                    a.download = that.info.Title;
                    a.click();
                }
            };
            xhr.send(null);
        },
        download() {
            
            const a = document.createElement("a");
            a.href = this.info.FileUrl;
            a.target = "_blank";
            a.download = this.info.FileUrl;
            a.click();

        },
         // 收藏
         ToggleCollectLibrary(){
            ToggleCollectLibrary(this.$route.params.id).then(res => {
                this.$message.success(res.msg)
                this.info.IsCollection  = !this.info.IsCollection 
            }).catch(err => {

            })
        }
    },
    mounted() {
        this.getData();
    },
};
</script>
<style lang="less" scoped>
.info {
    .auto-1440 {
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    :deep(.breadcrumb) {
        flex-shrink: 0;
    }

    .section {
        flex: 1;
        height: 0;
        padding-bottom: 30px;
    }
}

.section {
    display: flex;
    padding-top: 35px;
    padding-bottom: 110px;

    .left {
        flex: 1;
    }

    .right {
        width: 490px;
        flex-shrink: 0;
        padding-left: 30px;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
    }

    h1 {
        font-size: 20px;
        color: rgb(51, 51, 51);
        line-height: 1.4;
        font-weight: bold;
        margin-bottom: 15px;
        display: flex;

        span {
            flex: 1;
        }

        .ant-btn {
            flex-shrink: 0;
        }
    }

    .title-wrap {
        margin-bottom: 25px;
        flex-shrink: 0;

        .infoitem {
            display: flex;
            margin-bottom: 10px;

            .label {
                font-size: 18px;
                color: rgb(0, 0, 0);
                line-height: 30px;
                width: 80px;
                font-weight: bold;
                flex-shrink: 0;
            }

            .inner {
                flex: 1;
            }
        }
    }

    .relateModel {
        flex: 1;

        height: 0;
        min-height: 200px;

        :deep(& > div) {
            height: 100%;
            display: flex;
            flex-direction: column;

            .list {
                flex: 1;
                overflow-y: auto;
            }
        }
    }

    .des {
        font-size: 18px;
        color: rgb(85, 85, 85);
        line-height: 1.778;
    }

    .iframe {
        height: 100%;

        iframe {
            width: 100%;
            height: 1100px;
            border: 0;
        }
    }

    .pdf_wrap {
        position: relative;
        height: 100%;

        .btn {
            position: absolute;
            right: 100%;
            padding-right: 10px;

            .item {
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                font-size: 17px;
                color: rgb(136, 136, 136);
                border-radius: 3px;
                background-color: rgb(245, 245, 245);
                text-align: center;
                line-height: 1.2;
                padding: 0 10px;
                margin-bottom: 1px;
                transition: 0.3s;
                cursor: pointer;

                &.active,
                &:hover {
                    background-color: #00ffc0;
                    color: #fff;
                }
            }
        }
    }
}

.goback {
    position: absolute;
    left: 0.5rem;
    top: 0.2rem;
}
</style>
